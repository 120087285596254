import React, { Component } from 'react';
import data from './Data.json';

class Skills extends Component {
    renderSkills(skills) {
        return Object.keys(skills).map((category, ind) => (
            <div key={`list-${ind}`}>
                <h2>{category}</h2>
                {Object.keys(skills[category]).map((subCategory, subInd) => (
                    <div key={`sublist-${subInd}`}>
                        <h3>{subCategory}</h3>
                        <ul>
                            {skills[category][subCategory].map((skill, skillInd) => (
                                <li key={`skill-${skillInd}`}>{skill}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        ));
    }

    render() {
        return (
            <div>
                <div className="skilllist">
                    <p>
                        I work with a diverse set of technologies. Here are the ones I use most often:
                    </p>
                    {this.renderSkills(data.skills)}
                    <p>
                        Over the years I have worked with many different technologies.
                        I am always learning new things and expanding my skill set.
                        As I adapt to new technologies, sometimes I abandon old ones.
                        Here are some technologies I have worked with in the past:
                    </p>
                    {this.renderSkills(data.legacy)}
                </div>
            </div>
        );
    }
}

export { Skills };