import {RenderAnimatedText} from './Components/AnimatedText/AnimatedText';
import moment from 'moment';
import Portfolio from './Components/Portfolio/Portfolio';
import Projects from './Components/Projects/Projects';
import {About} from './Components/About/About';
import './App.scss';
import Space from './Components/Space/Space';
import Clients from './Components/Clients/Clients';
import Home from './Components/Home/Home';
import {
    Link,
    Routes,
    Route
} from "react-router-dom";
import { HashRouter as Router } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useState } from 'react';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faMastodon, faPython, faItchIo, faPatreon, faBluesky, faLinkedin } from '@fortawesome/free-brands-svg-icons';


import Stats from './Components/Stats/Stats';

function Page() {
    let { path, section } = useParams();

    console.log("Page: " + path + " " +section)

    let [state, setState] = useState({
        showmenu: false
    });

    const toggleCollapseMenu = () => {
        state.showmenu = !state.showmenu;
        console.log(state.showmenu);
        setState(state);
    }

    let page = path;
    let sec = " "+section;
    let space = <Space />;
    if (page === 'projects') {
        section = <Projects />
    }
    else if (['about', 'skills'].includes(page)) {
        section = <About section={section} />
    }
    else if (page === 'clients') {
        section = <Clients />
    }
    else if (page === 'portfolio') {
        section = <Portfolio section={section} />
    }
    else if (page === 'stats') {
        section = <Stats />
    }
    else {
        section = <Home />
    }

    return (
        <div className={"container " + page + sec}>
            <div id="home" className="App">
                <header>
                    <div className="grid">
                        <h1>
                            <Link to="/home">
                                <small><RenderAnimatedText text="|||" /></small>  joe<strong>curlee</strong>
                            </Link>
                        </h1>
                        <nav className={state.showmenu ? "menubuttonnav" : "menubuttonnav collapsed"}>
                            <ul className="menubutton">
                                <li><span onClick={toggleCollapseMenu} href="#"><i className="fas fa-bars"></i></span></li>
                            </ul>
                        </nav>
                        <nav className={state.showmenu ? "" : "collapsed"}>
                            <ul className="collapsablemenu">
                                <li className={(page === 'about') ? 'selected' : null}>
                                    <Link onClick={toggleCollapseMenu} to="/about">about</Link>
                                </li>
                                <li className={(page === 'portfolio') ? 'selected' : null}>
                                    <Link onClick={toggleCollapseMenu} to="/portfolio">portfolio</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </header>

                <div style={{display: "flex"}}>
                    <section key="section" className="cover pageContent">
                        <div className="content">
                            {section}
                        </div>
                    </section>
                </div>

                <footer>
                    <div className="grid">
                        <div>
                            <a href="https://bsky.app/profile/joecurlee.com" target="_blank" rel="noopener noreferrer" title="Bluesky">
                                <FontAwesomeIcon icon={faBluesky} />
                            </a>
                            <a href="https://github.com/w4ffl35" target="_blank" rel="noopener noreferrer" title="Github">
                                <FontAwesomeIcon icon={faGithub} />
                            </a>
                            <a href="https://mastodon.social/@w4ffl35" target="_blank" rel="noopener noreferrer" title="Mastodon social">
                                <FontAwesomeIcon icon={faMastodon} />
                            </a>
                            <a href="https:https://hachyderm.io/@w4ffl35" target="_blank" rel="noopener noreferrer" title="Hachyderm">
                                <FontAwesomeIcon icon={faMastodon} />
                            </a>
                            <a href="https://capsizegames.itch.io/" target="_blank" rel="noopener noreferrer" title="Itch.io">
                                <FontAwesomeIcon icon={faItchIo} />
                            </a>
                            <a href="https://pypi.org/user/w4ffl35/" target="_blank" rel="noopener noreferrer" title="PyPi">
                                <FontAwesomeIcon icon={faPython} />
                            </a>
                            <a href="https://patreon.com/w4ffl35" target="_blank" rel="noopener noreferrer" title="Patreon">
                                <FontAwesomeIcon icon={faPatreon} />
                            </a>
                            <a href="https://www.linkedin.com/in/joe-curlee/" target="_blank" rel="noopener noreferrer" title="LinkedIn">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                            <a href="https://pypi.org/user/w4ffl35/">
                                <FontAwesomeIcon icon={faPython} />
                            </a>
                        </div>
                        <div>
                            &copy; {moment().format('YYYY')} all rights reserved
                        </div>
                    </div>
                </footer>
                {space}
            </div>
        </div>
    );
}

function App() {
    return (
        <div className="theme-light">
            <Router key="router">
                <Routes>
                    <Route path={"/"} element={<Page />} />
                    <Route path={"/:path"} element={<Page />} />
                    <Route path={"/:path/:section"} element={<Page />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
