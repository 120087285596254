import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import image from './screenshot.png';

class WildWestPoker extends Component {
    render() {
        return (
            <div>
                <div key="intro" className="intro">
                    <h2>
                        <RenderAnimatedText text="PyMazeBuilder" />
                    </h2>
                    <p>
                        A retro poker game with a wild west theme. Play against an angry AI opponent in a game of Texas Hold'em.
                    </p>
                </div>
                <div className="grid">
                    <div>
                        <p>
                            PyMazeBuilder is a retro poker game with a wild west theme created for a game jam.
                            Play against an angry AI opponent in a game of Texas Hold'em. You can cheat but be careful,
                            if the AI catches you cheating its game over.
                        </p>
                        <p>
                            Built with Python.
                        </p>
                        <p>
                            <a href={"https://capsizegames.itch.io/wild-west-poker"}>Product page</a>
                        </p>
                    </div>
                    <div>
                        <a href={"https://capsizegames.itch.io/wild-west-poker"}>
                            <img src={image} alt="Wild West Poker" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default WildWestPoker;
