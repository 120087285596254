import React, { Component } from 'react';
import pic from './me.png';
import { Link } from 'react-router-dom'
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import {Skills} from './Skills';
import './About.scss';
import data from './Data';
import axios from 'axios';

class About extends Component {
    renderPage() {
        let section = this.props.section||'bio';
        let title;
        let pageContent;
        switch(section) {
            case 'skills':
                title = 'Skills';
                pageContent = <Skills />;
                break;
            case 'contact':
                title = 'Contact';
                pageContent = <Contact />;
                break;
            default:
                title = 'Bio';
                pageContent = <Bio />;
                break;
        }
        return (
            <div>
                <div className="intro">
                    <h2>
                        <RenderAnimatedText text={title} />
                        <Link className={section === 'skills' ? 'selected' : ''} to="/about/skills">skills</Link>
                        <Link className={section === 'contact' ? 'selected' : ''} to="/about/contact">contact</Link>
                        <Link className={section === 'bio' ? 'selected' : ''} to="/about/bio">bio</Link>
                    </h2>
                    <p>
                        Experienced software engineer skilled in Web Development, Software Development, and Game Development. Proficient in languages like Python, JavaScript, and C#, and familiar with frameworks such as Django, React, and Unity3D.
                    </p>
                </div>
                {pageContent}
            </div>
        );
    }

    render() {
        return (
            <div className="grid">
                <img src={pic} alt="Me at a party covered in strings" />
                {this.renderPage()}
            </div>
        );
    }
}

class Bio extends Component {
    render() {
        return (
            <div>
                <p>
                    I'm a software engineer with a passion for creating clean code and intuitive user experiences. My expertise spans Web Development using frameworks like Django and React, Software Development with Python and PyQt6, and Game Development using Unity3D and Godot.
                </p>
                <p>
                    I'm proficient in multiple programming languages including Python, Javscript / Typescript, and C#. I've worked on diverse projects ranging from web applications to desktop software and games, covering all phases of the development lifecycle.
                </p>
                <p>
                    I'm always eager to learn new technologies and take on challenging projects that allow me to expand my skill set.
                </p>
            </div>
        );
    }
}

class Contact extends Component {
    state = {email: '', subject: '', message: ''}

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        axios.post(`https://8f76287sx2.execute-api.us-east-1.amazonaws.com/deployed/sendmail`, {
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message
        }).then((response) => {
            let state = this.state;
            state.completed = true;
            this.setState(state);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    renderForm() {
        return (
            // <form onSubmit={this.handleSubmit}>
            //     <input name="email" value={this.state.email} placeholder="email" onChange={this.handleInputChange} />
            //     <input name="subject" value={this.state.subject} placeholder="subject" onChange={this.handleInputChange} />
            //     <textarea name="message" value={this.state.message} placeholder="message" onChange={this.handleInputChange} />
            //     <button>send message</button>
            // </form>
            <p>Direct all inquiries to <a href="mailto: joecurlee@fastmail.com">joecurlee@fastmail.com</a></p>
        );
    }

    renderFormCompleted() {
        return(
            <div>
                <p>Thank you, your message has been sent.</p>
            </div>
        );
    }

    render() {
        return (
            <div>
                {(this.state.completed) ? this.renderFormCompleted() : this.renderForm()}
            </div>
        )
    }
}

export {About, Contact};