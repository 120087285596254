import React, { Component } from 'react';
import {RenderAnimatedText} from '../AnimatedText/AnimatedText';
import './super_stack_solitaire.scss';
import image from './screenshot.png';

class SuperStackSolitaire extends Component {
    render() {
        return (
            <div>
                <div key="intro" className="intro">
                    <h2>
                        <RenderAnimatedText text="Super Stack Solitaire" />
                    </h2>
                    <p>
                        A retro-style solitaire video game.
                    </p>
                </div>
                <div className="grid">
                    <div>
                        <p>
                            Super Stack Solitaire is a retro-style solitaire game which allows players to choose between
                            two different game modes: Classic and Arcade along with two different styles of play:
                            Pyramid and Klondike.
                        </p>
                        <p>
                            Built with C#, Unity3D and Aesprite.
                        </p>
                        <p>
                            <a href={"https://capsizegames.itch.io/super-stack-solitaire"}>Product page</a>
                        </p>
                    </div>
                    <div>
                        <a href={"https://capsizegames.itch.io/super-stack-solitaire"}>
                            <img src={image} alt="Super Stack Solitaire" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default SuperStackSolitaire;
